<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-btn
        small
        fab
        dark
        color="info"
        :to="{ path: '/invoicelist' }"
        class="mx-1"
      >
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.meta.subtitle
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
      <v-btn
        small
        fab
        dark
        class="mx-1"
        color="accent"
        :to="{ path: '/paymentedit/-1/' + this.$route.params.invoiceid }"
      >
        <v-icon dark>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-container
        fluid
        fill-height
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters align-space-around justify-center>
          <v-col cols="12">
            <InteractiveTable
              ref="paymentList"
              class="lg-table"
              :headers="[
                { text: 'ID', value: 'id' },
                { text: 'Vrsta', value: 'type' },
                { text: 'Broj naloga', value: 'statement_num' },
                {
                  text: 'Povezani račun',
                  value: 'invoice_num',
                  align: 'right'
                },
                { text: 'Platitelj', value: 'sender_name', width: 250 },
                { text: 'Datum plaćanja', value: 'payment_date' },
                { text: 'Iznos', value: 'amount' },
                { text: 'Valuta', value: 'currency' }
              ]"
              apiLoad="payment/payments.php"
              :actions="[
                {
                  id: '1',
                  name: 'Uredi plaćanje',
                  path: '/paymentedit/#id#/' + this.$route.params.invoiceid,
                  icon: 'edit',
                  color: 'secondary'
                }
              ]"
              defaultFilter="sender_name"
              :queryFilter="this.$route.params"
              defaultSort="payment_date"
              defaultSortDesc
              negativeFilter
            ></InteractiveTable>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import InteractiveTable from "@/components/InteractiveTable.vue";

export default {
  name: "PaymentList",
  data() {
    return {};
  },
  components: {
    InteractiveTable
  },
  computed: {
    needsRefresh: function() {
      return this.$store.getters.paymentListNeedsRefresh;
    }
  },
  watch: {
    needsRefresh(needsRefresh) {
      if (needsRefresh) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      this.$refs.paymentList.reload();
      this.$store.dispatch("payment_list_refreshed");
    }
  }
};
</script>
