var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'card--flex-toolbar-xs': _vm.$vuetify.breakpoint.xsOnly,
    'card--flex-toolbar': _vm.$vuetify.breakpoint.smAndUp
  }},[_c('v-toolbar',{staticClass:"elevation-3",attrs:{"flat":"","dense":"","color":"secondary"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"info","to":{ path: '/invoicelist' }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("arrow_back_ios")])],1),_c('v-toolbar-title',{staticClass:"body-2 white--text"},[_vm._v(_vm._s(this.$route.meta.subtitle))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("autorenew")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"accent","to":{ path: '/paymentedit/-1/' + this.$route.params.invoiceid }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)],1),_c('v-card-text',{class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-container',{staticClass:"pt-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"no-gutters":"","align-space-around":"","justify-center":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('InteractiveTable',{ref:"paymentList",staticClass:"lg-table",attrs:{"headers":[
              { text: 'ID', value: 'id' },
              { text: 'Vrsta', value: 'type' },
              { text: 'Broj naloga', value: 'statement_num' },
              {
                text: 'Povezani račun',
                value: 'invoice_num',
                align: 'right'
              },
              { text: 'Platitelj', value: 'sender_name', width: 250 },
              { text: 'Datum plaćanja', value: 'payment_date' },
              { text: 'Iznos', value: 'amount' },
              { text: 'Valuta', value: 'currency' }
            ],"apiLoad":"payment/payments.php","actions":[
              {
                id: '1',
                name: 'Uredi plaćanje',
                path: '/paymentedit/#id#/' + this.$route.params.invoiceid,
                icon: 'edit',
                color: 'secondary'
              }
            ],"defaultFilter":"sender_name","queryFilter":this.$route.params,"defaultSort":"payment_date","defaultSortDesc":"","negativeFilter":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }